
import couponTypes from '@/utils/coupon-types';
import { Coupon, BatchData } from '@/models/coupon.model';
import { ref, toRef, computed, PropType, onMounted, watch } from 'vue';
import { useCouponStore } from '@/store/useCoupon';
import { rules, valid } from '@/store/stateless/rules';
import { storeToRefs } from 'pinia';

export default {
  name: 'coupon-options',
  emits: ['valid', 'couponFields'],
  props: {
    item: {
      required: true,
      type: Object as PropType<Coupon>,
    },
    isDisabled: {
      required: true,
      type: Boolean,
      default: false,
    },
    batchData: {
      type: Object as PropType<BatchData>,
      default: {} as BatchData,
    },
  },
  setup(props, { emit }) {
    const coupon = useCouponStore();
    const { isError, currentScanner, selectedCoupon, listAccounts } = storeToRefs(coupon);
    const { saveField } = useCouponStore();

    const item = toRef(props, 'item');
    const types = currentScanner.value.isAppMembre
      ? couponTypes.filter((ct) => ['CDI', 'CHO', 'CCC', 'CPP', 'ECR'].includes(ct.code))
      : couponTypes;

    const form = ref();
    const type = ref(item.value.type);
    const amount = ref(item.value.amount);
    const accountNumber = ref(item.value.accountNumber);
    const couponNumber = ref(item.value.couponNumber);
    const isActive = ref(item.value.isActive);
    const couponNumberLoading = ref(item.value.couponNumberLoading);
    const erpUrl = ref(null);

    onMounted(async () => {
      validate();
    });

    const validate = async () => {
      if (form.value) {
        const result = await form.value.validate();
        const valid = result.valid && type.value !== '';
        const validAccount =
          currentType.value && currentType.value.canHaveNoAccount ? true : isActive.value;

        emit('valid', valid && validAccount && vehicleId.value && driverId.value);

        if (valid) {
          const fields = {
            type: type.value,
            amount: amount.value,
            accountNumber: accountNumber.value,
            couponNumber: couponNumber.value,
          };

          emit('couponFields', fields);
        }

        return valid;
      }
    };

    const validateOptions = (params, value) => {
      const valid = !rules.coupon[params].some((fn) => typeof fn(value) === 'string');
      if (valid && value !== item.value[params]) {
        saveField(item.value.id, params, value);
        validate();
        item.value[params] = value;
      }
    };

    const formattedDate = computed(() => {
      const date = new Date(item.value.date.seconds * 1000);
      return date.toLocaleString();
    });

    const currentType = computed(() => {
      return couponTypes.find((x) => x.code === type.value);
    });

    const batchId = computed(() => item.value.batchId || props.batchData.batchId);
    const driverId = computed(() => item.value.driverId || props.batchData.driverId);
    const vehicleId = computed(() => item.value.vehicleId || props.batchData.vehicleId);
    // returns driverLicence or driverId
    const driverData = computed(() =>
      props.batchData.driverLicence || item.value.driverLicence || driverId.value);
    const vehicleData = computed(() => {
      // returns vehiclePlate(vignette) or vehicleId id
      if (props.batchData.vehicle) {
        return props.batchData.vehicle.vignette;
      }
      return item.value.vehiclePlate || vehicleId.value;
    });

    const isChange = ref(false);
    const checkAccountNumber = async () => {
      if (currentType.value?.code !== 'ECR' && currentType.value?.code !== 'CCC') {
        couponNumberLoading.value = true;
        selectedType();
        isChange.value = true;
        validate();
        const result = listAccounts.value.find((acc) => acc.ref === accountNumber.value);
        if (result && result.active) {
          isActive.value = true;
          couponNumberLoading.value = false;
          validateOptions('accountNumber', accountNumber.value);
        } else {
          isActive.value = false;
          couponNumberLoading.value = false;
        }
      } else {
        isActive.value = true;
        validate();
        validateOptions('accountNumber', accountNumber.value);
      }
    };

    const selectedType = () => {
      if (
        currentType.value?.code === 'CHO' &&
        accountNumber.value.toString().substring(0, 1) !== 'H'
      ) {
        accountNumber.value = `H${accountNumber.value}`;
      } else if (
        currentType.value?.code === 'CHO' &&
        accountNumber.value.toString().substring(0, 1) === 'H'
      ) {
        accountNumber.value = `${accountNumber.value}`;
      } else if (currentType.value?.code === 'CCC') {
        accountNumber.value = '6332'; // default account for CCC types
        validateOptions('accountNumber', accountNumber.value); // trigger save account number
      } else {
        accountNumber.value = accountNumber.value.toString().replace('H', '');
      }
    };

    watch(
      item,
      (newItem) => {
        type.value = newItem.type;
        amount.value = newItem.amount;
        couponNumber.value = newItem.couponNumber;
        if (newItem.type || newItem.accountNumber) {
          accountNumber.value = newItem.accountNumber;
          checkAccountNumber();
        }
      },
      { immediate: true },
    );

    return {
      selectedType,
      selectedCoupon,
      isChange,
      isActive,
      couponNumberLoading,
      isError,
      form,
      type,
      types,
      currentType,
      amount,
      accountNumber,
      couponNumber,
      rules,
      valid,
      formattedDate,
      erpUrl,
      currentScanner,
      validate,
      validateOptions,
      checkAccountNumber,
      batchId,
      driverId,
      vehicleId,
      driverData,
      vehicleData,
    };
  },
};
