import { defineStore } from 'pinia';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

export const useUserStore = defineStore('user', {
  state: () => ({
    email: '',
    password: '',
    auth: getAuth(),
    isLoggedIn: false,
    currentUser: {},
    isError: false,
    errorMessage: '',
    message: '',
    loading: false,
  }),

  getters: {
    getErrorResponse: () => {
      return 'error';
    },
  },

  actions: {
    /* eslint-disable */
    async signup() {
      try {
        const createUser = await createUserWithEmailAndPassword(
          this.auth,
          this.email,
          this.password,
        );
        this.currentUser = createUser.user;
      } catch (error) {
        console.log(error);
      }
    },

    async signin() {
      this.loading = true;
      try {
        const login = await signInWithEmailAndPassword(this.auth, this.email, this.password);
        this.currentUser = login.user;
        this.isError = false;
        this.errorMessage = '';
      } catch (error: any) {
        this.isError = true;
        this.errorMessage = this.errorMessages(error.code);
        throw error;
      } finally {
        this.loading = false;
      }
    },

    setAuth(user: any, login: boolean) {
      this.currentUser = user;
      this.isLoggedIn = login;
    },

    async signout() {
      return await signOut(this.auth);
    },

    errorMessages(error: string) {
      switch (error) {
        case 'auth/user-not-found':
          return 'Il n\'y a pas d\'enregistrement d\'utilisateur correspondant à cet identifiant. L\'utilisateur a peut-être été supprimé';
        case 'auth/wrong-password':
          return 'Le mot de passe est invalide ou l\'utilisateur n\'a pas de mot de passe.';
        case 'auth/invalid-email':
          return 'L\'adresse e-mail est mal formatée.';
        default:
          return '';
      }
    },
  },
});
