import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b07d2204"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ocr-image" }
const _hoisted_2 = { ref: "canvas" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "object"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "element",
        key: element.left,
        style: _normalizeStyle({
        bottom: element.bottom,
        left: element.left,
      }),
        onClick: ($event: any) => (_ctx.$emit('transfer', element))
      }, [
        (element.object)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(element.object.label), 1))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(element.text), 1)
      ], 12, _hoisted_3))
    }), 128))
  ]))
}