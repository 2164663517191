import { App } from 'vue';
import FsLightbox from 'fslightbox-vue/v3';
import authBanner from '@/components/auth/sideBanner.vue';
import authLogin from '@/components/auth/loginPartial.vue';
// etc components
import navDrawer from '@/components/etc/navDrawer.vue';
import snackBar from '@/components/etc/snackBar.vue';
import statusBadge from '@/components/etc/statusBadge.vue';
import currencyInput from '@/components/etc/CurrencyInput.vue';
import couponLoader from '@/components/etc/couponLoad.vue';
import samvaTable from '@/components/etc/samvaTable.vue';
import erpUrl from '@/components/etc/erpUrl.vue';
import couponStatistics from '@/components/etc/couponStatistics.vue';
// coupon components
import couponImage from '@/components/coupons/CouponImage.vue';
import oneCoupon from '@/components/coupons/OneCoupon.vue';
import oneCouponFilter from '@/components/coupons/OneCouponFilter.vue';
import couponOptions from '@/components/coupons/CouponOptions.vue';
import couponStatus from '@/components/coupons/CouponStatus.vue';
import couponReport from '@/components/coupons/CouponReport.vue';
import couponCropper from '@/components/coupons/Cropper.vue';
import couponCRO from '@/components/coupons/CouponCROImage.vue';
// cards
import cardCoupon from '@/components/cards/couponScanner.vue';
import sectionItem from '@/components/cards/sectionItem.vue';
// props
import driverLicence from '@/components/coupons/DriverLicence.vue';
// table item
import registrationsBody from '@/components/tables/registrationsBody.vue';
import couponListsBody from '@/components/tables/couponListsBody.vue';
import accountListsBody from '@/components/tables/accountListsBody.vue';
import profileDetails from '@/components/tables/profileDetails.vue';
// onboarding
import profilePic from '@/components/onboarding/profilePic.vue';
import profilDetails from '@/components/onboarding/profileDetails.vue';
import profileTab from '@/components/onboarding/profileTab.vue';
import profileBoard from '@/components/onboarding/profileBoard.vue';
// buttons
import requestApprobationButtons from '@/components/buttons/RequestApprobationButtons.vue';
import couponApprobationButtons from '@/components/buttons/CouponApprobationButtons.vue';
import navButtons from '@/components/buttons/navButtons.vue';
// modals
import listsCoupon from '@/components/modals/listsCoupon.vue';
import confirmDeleteModal from '@/components/modals/confirmDeleteModal.vue';


/**
 * Initialization of components
 * @params app vue instantces
 */

export default function Components(app: App<Element>) {
  const values = [
    { key: 'auth-banner', component: authBanner },
    { key: 'auth-login', component: authLogin },
    { key: 'etc-drawer', component: navDrawer },
    { key: 'etc-snackbar', component: snackBar },
    { key: 'etc-statusbadge', component: statusBadge },
    { key: 'coupon-image', component: couponImage },
    { key: 'coupon-loader', component: couponLoader },
    { key: 'coupon-report', component: couponReport },
    { key: 'coupon-cro', component: couponCRO },
    { key: 'samva-table', component: samvaTable },
    { key: 'erp-url', component: erpUrl },
    { key: 'coupon-statistics', component: couponStatistics },
    { key: 'one-coupon', component: oneCoupon },
    { key: 'one-coupon-filter', component: oneCouponFilter },
    { key: 'coupon-approbation-buttons', component: couponApprobationButtons },
    { key: 'nav-buttons', component: navButtons },
    { key: 'card-coupon', component: cardCoupon },
    { key: 'card-section', component: sectionItem },
    { key: 'prop-driver-licence', component: driverLicence },
    { key: 'currency-input', component: currencyInput },
    { key: 'coupon-options', component: couponOptions },
    { key: 'coupon-status', component: couponStatus },
    { key: 'coupon-cropper', component: couponCropper },
    { key: 'item-registration', component: registrationsBody },
    { key: 'item-coupon-lists', component: couponListsBody },
    { key: 'item-account-lists', component: accountListsBody },
    { key: 'item-profile', component: profileDetails },
    { key: 'onboarding-profile', component: profilePic },
    { key: 'onboarding-profile-details', component: profilDetails },
    { key: 'onboarding-profile-tab', component: profileTab },
    { key: 'onboarding-profile-board', component: profileBoard },
    { key: 'request-approbation-button', component: requestApprobationButtons },
    { key: 'modal-list', component: listsCoupon },
    { key: 'modal-confirm-delete', component: confirmDeleteModal },
    { key: 'fs-lightbox', component: FsLightbox },
  ];
  values.forEach((element) => {
    app.component(element.key, element.component);
  });
}
