const lists = [
    {
        value: 'accountNumber',
        text: 'Numéro de compte'
    },
    {
        value: 'couponNumber',
        text: 'Numéro de coupon'
    },
    {
        value: 'source',
        text: 'Source'
    },
    {
        value: 'state',
        text: 'État'
    },
    {
        value: 'type',
        text: 'Taper'
    },
    {
        value: 'amount',
        text: 'Montante'
    },
    {
        value: 'date',
        text: 'Date',
    },
];

export default lists;
